<template lang="pug">
section#signup
    .clearfix
        h1 Hello
        h3.subtitle Please, enter you information

        form(@submit.prevent)
            input(type="text", name="name", autocorrect="off", autocapitalize="none", placeholder="Han Solo", v-model="name")
            input(type="text", name="username", autocorrect="off", autocapitalize="none", placeholder="han_solo", v-model="username")
            input(type="email", name="email", autocorrect="off", autocapitalize="none", placeholder="hsolo@millfalcon.com", v-model="email")
            input(type="password", placeholder="******", v-model="password")

            router-link.link(:to="{ name: 'login' }") Already have an account? Login

            .error(v-if="error") {{ error }}

            button(v-if="!loading", @click="signup") Signup
            .spinner(v-if="loading")
</template>

<script>
import { signup, login, logout } from '@/services/user-service';

export default {
    data: () => ({
        loading: false,
        error: undefined,
        name: undefined,
        username: undefined,
        email: undefined,
        password: undefined
    }),
    async mounted() {
        logout();
    },
    methods: {
        signup: async function () {
            let signedUp = false;
            try {
                this.loading = true;
                let username = this.username;

                if (username && username.includes('@')) {
                    username = username.split('@')[0];
                }

                signedUp = !!await signup({
                    name: this.name,
                    username,
                    email: this.email,
                    password: this.password
                });

                await login(username, this.password);

                this.$router.push('dashboard');
            } catch (error) {
                // eslint-disable-next-line
                console.error(error);
                if (error && error.response && error.response.data 
                    && error.response.data.err && error.response.data.err.length) {
                    this.error = error.response.data.err;
                } else {
                    this.error = 'Something went wrong. Please try again later or contact support@schemadb.com';
                }

                if (signedUp) {
                    this.$router.push('login');
                }
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
<style scoped>
section#signup {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    width: 100vw;
    min-height: 100vh;
    z-index: 999;
    background: #fff;
    padding: var(--padding-large);
    padding-top: 15vh;
    text-align: center;
    max-width: 100vw;
}

.clearfix {
    text-align: left;
    max-width: 400px;
    margin: 0 auto;
}

h1 {
    margin-bottom: var(--margin-small);
}

h3.subtitle {
    font-weight: 500;
    color: #999;
    margin-bottom: 60px;
}

input {
    outline: none;
    box-shadow: none;
    border: none;
    width: 100%;
    border-radius: 0;
    border-bottom: 1px solid #666;
    padding: var(--padding-small) 0;
    margin-bottom: var(--margin-large);
    font-size: var(--font-size-small);
}
input:last-of-type {
    margin-bottom: var(--margin-base);
}

.link {
    position: relative;
    display: block;
    width: 100%;
    font-size: var(--font-size-small);
    margin-bottom: var(--margin-large);
    opacity: .6;
}

button {
    position: relative;
    display: block;
    outline: none;
    color: #fff;
    background: var(--text-color);
    border: none;
    width: 100%;
    cursor: pointer;
    padding: var(--padding-base) var(--padding-base);
    text-transform: uppercase;
    font-weight: bold;
    font-size: var(--font-size-small);
    border-radius: 100px;
    margin-top: var(--margin-base);
    box-shadow: 0px 4px 8px rgba(64,64,64,.2);

    -webkit-transition: all ease-in-out .2s;
    -moz-transition: all ease-in-out .2s;
    -o-transition: all ease-in-out .2s;
    transition: all ease-in-out .2s;
}

button:active {
    box-shadow: none;
    transform: scale(.96);
}
.error {
    padding: var(--padding-base);
    border-radius: 6px;
    line-height: 20px;
    background: #FFECE7;
    color: var(--text-color-red);
    font-size: var(--font-size-small);
    font-weight: 500;
    box-shadow: 0px 0px 8px rgba(64,64,64,.1);
    margin: var(--margin-base) 0;
    margin-bottom: calc(var(--margin-base) * 2);
}

.spinner {
    width: 40px;
    height: 40px;
    margin: var(--margin-base) auto;
    background-color: #999;

    border-radius: 100%;  
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
    0% { -webkit-transform: scale(0) }
    100% {
    -webkit-transform: scale(1.0);
        opacity: 0;
    }
}

@keyframes sk-scaleout {
    0% { 
    -webkit-transform: scale(0);
        transform: scale(0);
    } 100% {
    -webkit-transform: scale(1.0);
        transform: scale(1.0);
        opacity: 0;
    }
}
</style>
